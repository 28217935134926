import React, { useState, useEffect } from "react"
import styles from "../styles/quilmesTourStyles/validator.module.css"
import logoTour from "../images/logos/LCDQ_blanco.png"
import ReactSwipeButton from "../components/lacasadequilmes/ReactSwipeButton"
import { useLocation } from "@reach/router"
import queryString from "query-string"

const Validator = () => {
  const location = useLocation()
  const query = queryString.parse(location.search)

  const [status, setStatus] = useState("input")
  const [password, setPassword] = useState("")
  const [correctPassword, setCorrectPassword] = useState(false)

  // const api = "http://localhost:8080/api"
  const api = "https://wacopaamericamotmback-prd.azurewebsites.net/api"

  useEffect(() => {
    if (password === "LCDQ") setCorrectPassword(true)
    else correctPassword && setCorrectPassword(false)
  }, [password])

  const handleSubmit = e => {
    e.preventDefault()
    if (correctPassword) setStatus("swipe")
  }

  const onSuccess = () => {
    fetch(`${api}/tour/reservation/${query.code}/validate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async response => {
        if (response.ok) {
          setStatus("success")
        } else {
          throw new Error(response)
        }
      })
      .catch(error => console.error("Error cargando los datos. \n", error))
  }
  return (
    <div
      className={`${styles.mainContainer} ${
        status === "success" && styles.successBackground
      }`}
    >
      <img src={logoTour} className={styles.logo}></img>
      <div className={styles.reservationDetails}>
        <hr />
        <div className={styles.title}>Datos de la reserva</div>
        <hr />
        <div className={styles.key}>Nombre</div>
        <div className={styles.value}>{query.name}</div>
        <div className={styles.key}>E-mail</div>
        <div className={styles.value}>{query.email}</div>
        <div className={styles.key}>Tour</div>
        <div className={styles.value}>{query.tour}</div>
        <div className={styles.key}>Fecha</div>
        <div className={styles.value}>{query.date}</div>
        <div className={styles.key}>Horario</div>
        <div className={styles.value}>{query.time} HS</div>
      </div>
      <div className={styles.inputOrSwipeContainer}>
        {status === "input" && (
          <form className={styles.successText} onSubmit={handleSubmit}>
            <input
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Ingresá la palabra clave"
              className={styles.input}
            ></input>
            <button
              className={`${styles.okButton} ${
                correctPassword && styles.activeOkButton
              }`}
            >
              OK
            </button>
          </form>
        )}
        {status === "swipe" && (
          <ReactSwipeButton
            text="Deslizá para validar"
            color="#0f91d7"
            onSuccess={onSuccess}
          />
        )}
        {status === "success" && (
          <div className={styles.successText}>
            Tu código fue validado exitosamente
          </div>
        )}
      </div>
    </div>
  )
}

export default Validator
